<template>
    <div class="container">
        <div v-if="logs.length>0">
            <div class="order">订单编号：{{pageInfo.ordersn}} 下单时间：{{pageInfo.ctime}}</div>
            <el-tabs type="border-card" class="logis" :activeName="activeName">
                <el-tab-pane v-for="(item,key) in logs" :key="key" :label="'物流'+(key+1)" :name="key + ''">
                    <div class="logisInfo">
                        <div class="infoLeft">
                            <div class="goods">
                                <img :src="item.list.thumb" />
                                <p class="total">共{{item.list.number}}件商品</p>
                            </div>
                            <div>快递公司：{{item.list.express}}</div>
                            <div>
                                快递单号：{{item.list.expresssn}}
                                <span style="cursor: pointer;" @click="cheangeExpresssn(item.id)">修改</span>
                            </div>
                            <div>发货时间：{{item.list.delivery_time}}</div>
                            <el-button type="primary">确认收货</el-button>
                        </div>
                        <div class="infoRight">
                            <el-timeline :reverse="reverse">
                                <el-timeline-item class="red" v-for="(activity, index) in item.data" :key="index" :timestamp="activity.ftime" :color="activity.color">{{activity.context}}</el-timeline-item>
                            </el-timeline>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-else>暂无物流信息</div>
        <el-dialog title="请填写物流信息" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="left">
                <el-form-item>
                    <el-select placeholder="请选择物流公司" v-model="form.express_id">
                        <el-option v-for="item in expressList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="请输入快递单号" v-model="form.expresssn"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
import { request } from '@/common/request';
import { deal } from '@/common/main';

export default {
    data() {
        return {
            expressList: [],
            addVisible: false,
            activeName: '0',
            pageInfo: {},
            id: 0,
            form: {
                order_goods_id: 0,
            },
            rules: {},
            logs: [
            ],
            activities: [{
                content: '【郑州市】 【郑州金水二部】（0371-55073621） 的 五万 （13783644655） 已揽收',
                timestamp: '2018-12-19 07:07'
            }, {
                content: '【郑州市】 快件离开 【郑州金水二部】 已发往 【盘锦中转】',
                timestamp: '2018-12-19 07:07'
            }, {
                content: '【郑州市】 快件已经到达 【郑州中转】',
                timestamp: '2018-04-11'
            }],
            reverse: false,
            list: [
            ]
        };
    },
    mounted() {
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id != this.id && to.path == "/order-logistics") {
                    this.id = to.query.id;
                    if (to.query.key) {
                        this.activeName = to.query.key
                    }
                    this.loadData();
                }
                //深度监听，同时也可监听到param参数变化
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        // 获取所有物流公司
        getExpressList() {
            request.get('/common/express/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.expressList = ret.data.list
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadData() {
            this.loading = true;
            const _this = this
            request.get('/order/detail', { id: this.id }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data.list
                    let list = ret.data.list;
                    this.pageInfo.pay_time = deal.timestamp(this.pageInfo.pay_time, 'unix');
                  for (let i = 0; i <this.pageInfo.goods.length; i++) {
                    this.pageInfo.goods[i].list.delivery_time = deal.timestamp(this.pageInfo.goods[i].list.delivery_time, 'unix')
                  }
                    //获取商品对应物流信息
                    for (let i = 0; i < list.goods.length; i++) {
                        _this.loadLogInfo(list.goods[i].list.expresscom, list.goods[i].list.expresssn)
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //获取物流信息
        loadLogInfo(expresscom, expresssn) {
            request.get('/order/logistics', { expresscom: expresscom, expresssn: expresssn }).then(ret => {
                if (ret.code == 1 && ret.data.list) {
                    this.logs.push(ret.data.list)
                    this.logs.map((items, index) => {
                        if (items.data) {
                            return items.data.map((item, index) =>
                                item.color = '#20a0ff'
                            )
                        }
                    })
                    this.logs.map((item, key) => {
                        item = Object.assign(item, this.pageInfo.goods[key])
                        return item
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        cheangeExpresssn(id) {
            this.form = {
                order_goods_id: id,
            },
                this.getExpressList()
            this.addVisible = true
        },
        // 发货
        saveData() {
            request.post('/order/logistics/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('修改成功');
                    this.addVisible = false
                    this.$router.go(0)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.order {
    color: #333;
    font-size: 13px;
}

.logis {
    margin-top: 20px;
}

.logisInfo {
    width: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;

    .infoLeft {
        width: 200px;

        div {
            color: #999;
            font-size: 12px;
            line-height: 25px;

            span {
                color: #409eff;
            }
        }

        .goods {
            position: relative;

            img {
                width: 50px;
                height: 50px;
            }

            .total {
                position: absolute;
                left: -5px;
                bottom: 5px;
                width: 60px;
                height: 20px;
                background: rgba(0, 0, 0, 0.6);
                color: #fff;
                text-align: center;
                line-height: 20px;
                font-size: 11px;
            }
        }
    }

    .infoRight {
        flex: 1;
        padding-left: 20px;
        box-sizing: border-box;
        padding-top: 20px;
        border-left: 1px solid #f2f2f2;
    }
    //  .infoRight .red .el-timeline-item__wrapper
    .red .el-timeline-item__content {
        color: red !important;
    }
}
</style>
